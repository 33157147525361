import useViewport from '@/hooks/useBBViewport'
import { WebVideo as AlliumWebVideo } from '@telus-uds/components-web'
import React, { useContext } from 'react'
import { YouTubeEvent } from 'react-youtube'
import { ContentfulAnalyticsEventType } from 'src/siteBuilder/utils/types/analytics'
import useTriggerAnalyticsEvent from '../../../../hooks/useTriggerAnalyticsEvent'
import { SiteBuilderContext } from '../../../renderer/context/SiteBuilderContext'

import { pickDataAttrs } from '@/siteBuilder/utils/dataAttributes'
import { contentfulLoader } from '../Image/contentfulLoader'

import { VIEWPORT_SIZES } from '@/constants/VIEWPORT'
import { changeAdobeDataLayer } from '@/siteBuilder/utils/alterDataLayers'
import { isNil } from '@/siteBuilder/utils/isNil'
import { EventType, generateAnalyticsObjectForYoutubeEvent } from '@/siteBuilder/utils/videoAnalytics'
export type VideoYouTubeProps = {
  videoId: string
  videoLength: number
  aspectRatio?: '16:9' | '4:3' | '1:1'
  beginMuted?: boolean
  defaultVolume?: number
  posterSrc?: string
  linkAnalyticsEvent?: ContentfulAnalyticsEventType
}

/* istanbul ignore next */
const VideoYouTube = ({
  videoId,
  videoLength,
  aspectRatio = '16:9',
  beginMuted = true,
  defaultVolume = 1,
  posterSrc,
  linkAnalyticsEvent,
  ...rest
}: VideoYouTubeProps) => {
  const { locale, isWebpSupported } = useContext(SiteBuilderContext)
  const [isAnalyticsTriggered, setIsAnalyticsTriggered] = React.useState(false)
  const viewport = useViewport()
  const currentVideoState = React.useRef<number>(-1)

  useTriggerAnalyticsEvent(linkAnalyticsEvent, isAnalyticsTriggered)

  if (!isNil(posterSrc) && isNil(viewport)) return null

  const videoOnStartCallback = () => {
    setIsAnalyticsTriggered(true)
  }

  const onPlayEvent = (event?: YouTubeEvent<number>) => {
    if (currentVideoState.current === 2) {
      changeAdobeDataLayer(
        generateAnalyticsObjectForYoutubeEvent(EventType.RESUME, videoId, event.target),
        isAnalyticsTriggered
      )
    } else {
      changeAdobeDataLayer(
        generateAnalyticsObjectForYoutubeEvent(EventType.PLAY, videoId, event.target),
        isAnalyticsTriggered
      )
    }
    currentVideoState.current = 1
  }

  const onPauseEvent = (event?: YouTubeEvent<number>) => {
    currentVideoState.current = 2
    changeAdobeDataLayer(
      generateAnalyticsObjectForYoutubeEvent(EventType.PAUSE, videoId, event.target),
      isAnalyticsTriggered
    )
  }

  const onCompleteEvent = (event?: YouTubeEvent<number>) => {
    currentVideoState.current = 0
    changeAdobeDataLayer(
      generateAnalyticsObjectForYoutubeEvent(EventType.COMPLETE, videoId, event.target),
      isAnalyticsTriggered
    )
  }
  // TODO: remove wrapper div when the new EHC is pushed
  return (
    <div>
      <AlliumWebVideo
        copy={locale.language}
        videoId={videoId}
        videoLength={videoLength}
        aspectRatio={aspectRatio}
        beginMuted={beginMuted}
        defaultVolume={defaultVolume}
        posterSrc={posterSrc && contentfulLoader(isWebpSupported)({ src: posterSrc, width: VIEWPORT_SIZES[viewport] })}
        onStart={videoOnStartCallback}
        onPlay={onPlayEvent}
        onEnd={onCompleteEvent}
        onPause={onPauseEvent}
        {...pickDataAttrs(rest)}
      />
    </div>
  )
}

export default VideoYouTube
